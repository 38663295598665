import React from 'react'

function Error404 () {
  return (
    <section className="section App-header">
      <h1>Error 404</h1>
    </section>

  )
}

export default Error404
