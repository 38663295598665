import React from 'react'

function Satelites () {
  return (
    <section className="mt-5">
      <h1>Satélites</h1>
      <div className="contenedor">
        <a href="https://youtu.be/yc1wVjQkxz4" className="btn btn-primario">Youtube</a>
      </div>

    </section>
  )
}

export default Satelites
