import React from 'react'

import './index.css'
import App from './App'

import MouseContextProvider from './context/mouse-context'

import { createRoot } from 'react-dom/client'
const container = document.getElementById('root')
const root = createRoot(container)
// createRoot(container!) if you use TypeScript
root.render(
  <MouseContextProvider>
    <App />
  </MouseContextProvider>
)
