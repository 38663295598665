import React from 'react'

function Aviso () {
  return (
    <section data-aos="fade-up" className="section">
      <div className="contenedor aviso-contenedor">
        <h1 >AVISO DE PRIVACIDAD INTEGRAL<br />
          REDES SOCIALES Y WEB</h1>

        <p className="titulo">¿Quién es el responsable de sus datos personales?</p>
        <p>
          En virtud de lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la “LFPD”) y resto de disposiciones aplicables, Marketing and Media Gopharma S.A. de C.V. (en adelante, indistintamente el “Responsable” o “GO PHARMA”) es el Responsable de sus datos personales. Nuestro domicilio para oír y recibir notificaciones es en Calle Santa Margarita número 515, Colonia Insurgentes San Borja , Alcaldía Benito Juárez, C.P. 03100, Ciudad de México.
        </p>
        <p className="titulo">
          ¿Qué datos usamos y podemos solicitar?
        </p>
        <p>Para cumplir con las finalidades descritas en este aviso, GO PHARMA recaba o puede recabar las siguientes categorías de datos personales:</p>
        <ol>
          <li>Datos de identificación y de contacto;</li>
          <li>Datos de características personales;</li>
          <li>Datos de circunstancias sociales; y</li>
          <li>Datos patrimoniales.</li>
        </ol>

        <p className="titulo">¿Solicitamos datos sensibles</p>
        <p>GO PHARMA no solicita datos personales sensibles para las finalidades enumeradas en el siguiente apartado. Como titular-usuario de redes sociales, usted debe abstenerse de proporcionar ese tipo de información a GO PHARMA</p>
        <p className="titulo">
          Responsabilidad de GO PHARMA, de los Titulares-Usuarios y Prestadores de Servicios de Redes Sociales
          y Web
        </p>
        <p>GO PHARMA trata datos personales de los titulares-usuarios de redes sociales mediante el acceso y manejo de
          la información que dichos titulares-usuarios publican o difunden a través de los perfiles que han creado en cada
          una de las redes sociales que utilizan para vincularse con GO PHARMA. Este tratamiento también comprende el
          aprovechamiento de los datos personales para fines de divulgación de actividades de GO PHARMA o su grupo
          de empresas</p>
        <p>GO PHARMA accede, maneja y aprovecha los datos personales de los titulares-usuarios de redes sociales y web
          únicamente durante el plazo en que los titulares-usuarios permanecen vinculados a los perfiles que GO PHARMA
          administra en cada una de las redes sociales y web implicadas</p>
        <p>Los titulares-usuarios son responsables de la exactitud, veracidad y actualización de los datos personales que
          publican en sus perfiles de redes sociales, así como del grado de difusión de su información y del acceso a la
          misma que permiten o autorizan a terceros a través de dichos perfiles. Se hace la recomendación a todos los
          titulares-usuarios de redes sociales revisar continuamente la configuración de privacidad de sus perfiles en cada
          uno de los sitios web que utilizan para vincularse a GO PHARMA.</p>
        <p>Los prestadores de servicios de redes sociales y web son responsables de las bases de datos creadas con los
          datos personales de los usuarios de dichas redes. Dichos prestadores de servicios son a su vez responsables de
          las medidas de seguridad que adopten para salvaguardar los datos personales de sus usuarios.
          Conforme a lo anterior, GO PHARMA se obliga a proporcionar un adecuado acceso, manejo y aprovechamiento
          de los datos personales de los titulares-usuarios que se vinculan a los perfiles que GO PHARMA administra en
          diversas redes sociales y web.</p>
        <p className="titulo">Finalidades del tratamiento de datos personales</p>
        <p>GO PHARMA podrá tratar sus datos personales para las siguientes finalidades: </p>
        <p className="titulo">Finalidades Primarias:</p>
        <ol>
          <li>Gestión de seguidores y perfiles en redes sociales y web de las cuentas oficiales de GO PHARMA.</li>
          <li>Gestión de comunicaciones y seguimiento de actividades de GO PHARMA.</li>
          <li>Estadística de seguidores en redes sociales y web.</li>
          <li>Atención y seguimiento a quejas o dudas en relación con los servicios de GO PHARMA. </li>
          <li>Proporcionar información de los servicios de GO PHARMA.</li>
        </ol>
        <p className="titulo">Finalidades Secundarias:</p>
        <p>No existen.</p>
        <p className="titulo">Transferencias de datos personales</p>
        <p>Para las finalidades indicadas en el apartado anterior, no se realizan transferencias de datos a terceros.</p>
        <p className="titulo">Derechos ARCO</p>
        <p>La Ley regula los Derechos ARCO con que usted cuenta como titular de datos personales. Estos derechos
          comprenden:</p>
        <ul>
          <li>Acceso: el derecho de conocer qué datos personales tratamos sobre Usted, así como información relativa a
            las condiciones y generalidades del tratamiento.</li>
          <li>Rectificación: el derecho de solicitar en todo momento la rectificación de sus datos que resulten inexactos o
            incompletos.</li>
          <li>Cancelación: el derecho a que GO PHARMA cese el tratamiento de sus datos personales, a partir de un
            bloqueo y su posterior supresión. </li>
          <li>Oposición: el derecho a oponerse, por causa legítima, al tratamiento de sus datos personales.</li>
        </ul>
        <p>Para ejercer cualquiera de los Derechos ARCO, Usted deberá presentar una solicitud dirigida a nuestro
          Departamento de Datos Personales, a través de cualquiera de los siguientes canales:</p>
        <ol className="low">
          <li>Enviando una solicitud al correo electrónico info@go.pharma.mx, o</li>
          <li>Enviando una solicitud debidamente firmada a la dirección, Calle Santa Margarita número 515,Colonia
            Insurgentes San Borja , Alcaldía Benito Juárez, C.P 03100, Ciudad de México.</li>
        </ol>
        <p>La solicitud deberá contener o estar acompañada de:</p>
        <ol className="low">
          <li>Su nombre y domicilio completos, u otro medio para comunicar la respuesta a su solicitud;</li>
          <li>Copia de un documento que acredite su identidad y en su caso, la representación legal si alguien ejerce
            el derecho en su nombre. Podrá omitir este requisito si aporta información que lo acredite como cliente
            de GO PHARMA y lo identifique de forma indubitable;</li>
          <li>Una descripción clara y precisa del Derecho ARCO que desea ejercer y de los datos personales
            relacionados con su solicitud; y</li>
          <li>En su caso, cualquier otra información o documento que nos ayude a localizar sus datos personales</li>
        </ol>
        <p>GO PHARMA responderá a su solicitud dentro de los 20 (veinte) días hábiles siguientes a la fecha en que sea
          enviada y recibida. Si la solicitud resulta procedente, GO PHARMA la hará efectiva dentro de los 15 (quince) días
          hábiles siguientes a la fecha en que comunique la respuesta. En caso de que de la información y/o documentación
          proporcionados en su solicitud resulten incompletos, erróneos y/o insuficientes, o bien, no se acompañen los
          documentos necesarios para acreditar su identidad o la representación legal correspondiente, GO PHARMA
          solicitará la corrección y subsanación de las deficiencias para poder dar trámite a su solicitud. Usted contará con
          10 (diez) días hábiles para atender el requerimiento y corrección de la solicitud; en caso contrario ésta se tendrá
          por no presentada.</p>
        <p>El uso de medios electrónicos para el ejercicio de los Derechos ARCO autoriza al Responsable para dar respuesta
          a la solicitud correspondiente a través del mismo medio, salvo que Usted indique otro medio de contacto en su
          solicitud, de forma clara y expresa.</p>
        <p>El derecho de cancelación no es absoluto. Por favor tome en cuenta que GO PHARMA debe conservar
          información para cumplir con diversas obligaciones legales y que para hacerlo puede compartir sus datos
          personales con otras entidades u organismos. En tales casos, es posible que el derecho de cancelación deba
          solicitarse ante la entidad que recibió sus datos personales.</p>
        <p className="titulo">Ejercicio de los derechos ARCO ante los prestadores de servicios de redes sociales y web</p>
        <p>El ejercicio de los derechos ARCO frente a los prestadores de servicios de redes sociales en que los titularesusuarios de dichas redes hayan creado un perfil se regirá, conforme a la legislación aplicable, en los términos y
          condiciones establecidos en los Avisos de Privacidad, Políticas de Privacidad y/o Avisos Legales que cada
          prestador de servicios haya dispuesto en las redes sociales que operan y administran.</p>
        <p className="titulo">¿Quiere revocar su consentimiento o limitar el uso de sus datos?</p>
        <p>Usted podrá revocar el consentimiento para el tratamiento o transferencia de sus datos personales; esta
          revocación puede tener como efecto que no podamos prestarle algunos servicios. Si existen causas de interés
          público u obligaciones legales relacionados con la seguridad de nuestros clientes, es posible que podamos negar
          la revocación de su consentimiento para determinadas finalidades.</p>
        <p>Si usted revoca su consentimiento y dicha revocación es procedente, GO PHARMA dejará de tratar sus datos
          personales para las finalidades indicadas en este Aviso de Privacidad que resulten procedentes o expresamente
          solicitadas. Sin perjuicio de lo anterior, la revocación de su consentimiento no podrá tener por objeto garantizado
          que los terceros que han tenido acceso a sus datos personales dejen de tratarlos de inmediato, ni en el futuro,
          porque ellos pueden usar su información para finalidades propias y bajo sus propios avisos de privacidad.</p>
        <p>Usted podrá enviar su solicitud de revocación de consentimiento al correo electrónico info@go.pharma.mx,
          siguiendo las instrucciones aplicables al ejercicio de los Derechos ARCO.</p>
        <p>También puede limitar el uso o divulgación de sus datos personales, dirigiendo la solicitud correspondiente a
          nuestro Departamento de Datos Personales. Los requisitos para acreditar tu identidad, así como el procedimiento
          para atender su solicitud serán los mismos que los señalados para el ejercicio de los derechos ARCO.</p>
        <p>GO PHARMA cuenta con medios y procedimientos para asegurar la inclusión de algunos de tus datos en listados
          de exclusión propios, cuando solicitas su inclusión en ellos de forma expresa. En tales casos, GO PHARMA
          otorgará a los titulares que soliciten su registro la constancia de inscripción correspondiente.</p>
        <p className="titulo">Cambios a nuestro Aviso de Privacidad</p>
        <p>Nuestra página web y nuestros servicios estarán en constante actualización para incluir nuevas funciones que
          sean útiles para nuestros prospectos y clientes y, por lo tanto, nuestro Aviso de Privacidad podrá cambiar para
          reflejar estas mejoras.</p>
        <p>GO PHARMA podrá modificar, actualizar, extender o de cualquier otra forma cambiar el contenido y alcance de
          este Aviso de Privacidad, en cualquier momento y bajo nuestra completa discreción. En todo momento, podrá
          solicitar la última versión de este Aviso de Privacidad a nuestro Departamento de Datos Personales, a través del
          correo electrónico info@go.pharma.mx Cuando resulte legalmente procedente y contemos con su correo
          electrónico, GO PHARMA podrá comunicar cambios al presente Aviso de Privacidad a través de ese medio de
          comunicación.</p>
        <p>El Aviso de Privacidad que regule el tratamiento de sus datos personales será el que aparezca publicado en
          nuestra página web e identifique el tipo de relación que usted tiene con GO PHARMA. Podrá acceder a la última
          versión de este Aviso de Privacidad a través de https://go-pharma.mx/, sección Avisos de Privacidad.</p>
        <p>Si ha tenido acceso a este Aviso de Privacidad por medios electrónicos o si desea comunicar su negativa para el
          tratamiento de tus datos para finalidades secundarias, puede comunicar esta decisión en cualquier momento a
          través de los medios establecidos para el ejercicio de sus derechos ARCO.</p>
        <p className="titulo">Dudas o aclaraciones</p>
        <p>En caso de tener dudas o aclaraciones sobre el presente Aviso de Privacidad, puede contactar a nuestro
          Departamento de Datos Personales a través del correo electrónico info@go.pharma.mx.</p>
        <p style={{ textAlign: 'right' }}>Última actualización: 01 de octubre de 2021</p>
      </div>

    </section>
  )
}

export default Aviso
